import Vuex from 'vuex'

export default new Vuex.Store({
  state: {
    // 在这里定义你的状态
    chatItems: [],
    chatIdSelected: null,
    merchantGuid: localStorage.getItem('merchantGuid') || ''
  },
  mutations: {
    // 在这里定义如何修改你的状态
    setMerchantGuid (state, merchantGuid) {
      state.merchantGuid = merchantGuid;
      localStorage.setItem('merchantGuid', merchantGuid);
    },
    clearMerchantGuid (state) {
      state.merchantGuid = '';
      localStorage.removeItem('merchantGuid');
    }
  },
  actions: {
    // 在这里定义异步操作
  },
  getters: {
    chatItemSelected: state => {
      return state.chatItems.find(item => item.id === state.chatIdSelected);
    },
  }
})
