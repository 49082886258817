<template>
	<el-aside width="76px" class="layout-sider">
		<div class="menus">
			<div class="item" @click="goLink('chat')" :class="pageName == 'chat' ? 'active' : ''">
				<div class="icon">
					<img :src="pageName == 'chat' ? iconPath.chatOn : iconPath.chat" />
				</div>
				<div class="text">AI 对话</div>
			</div>

			<div class="item" @click="goLink('draw')" :class="pageName == 'draw' ? 'active' : ''">
				<div class="icon">
					<img :src="pageName == 'draw' ? iconPath.drawOn : iconPath.draw" />
				</div>
				<div class="text">AI 绘画</div>
			</div>

			<!-- <div class="item" @click="goLink('gallery')" :class="pageName == 'gallery' ? 'active' : ''">
					<div class="icon">
						<img :src="pageName == 'gallery' ? iconPath.draw : iconPath.drawOn" />
					</div>
					<div class="text">画廊</div>
				</div> -->
			<div class="item" @click="goLink('tools')" :class="pageName == 'tools' ? 'active' : ''">
				<div class="icon">
					<img :src="pageName == 'tools' ? iconPath.toolOn : iconPath.tool" />
				</div>
				<div class="text">AI智能体</div>
			</div>
			<div class="item" @click="goLink('cooperation')" :class="pageName == 'cooperation' ? 'active' : ''">
				<div class="icon">
					<img :src="pageName == 'cooperation' ? iconPath.cooperationOn : iconPath.cooperation" />
				</div>
				<div class="text">协作空间</div>
			</div>
			<div
				class="item"
				:class="nowCategory == url.guid ? 'active' : ''"
				v-for="(url, idx) of urls"
				:key="idx"
				@click="getMerchantPcTools(url.guid)"
			>
				<div class="icon">
					<!-- <a target="_blank" :href="url.urlLink"> -->
					<img :src="url.categoryIcon" style="width: 32px; height: 32px" />
					<!-- </a> -->
				</div>
				<div class="text">{{ url.categoryName }}</div>
			</div>
		</div>
		<div class="other-url-pop" v-if="isPop">
			<div class="content">
				<div class="item" v-for="item in otherUrls" :key="item.guid" @click="onOpen(item.urlLink)">
					<div class="icon">
						<img class="img" :src="item.urlIcon" style="width: 32px; height: 32px" />
					</div>
					<div class="text">{{ item.urlTitle }}</div>
				</div>
			</div>
		</div>
	</el-aside>
</template>

<script>
// import { svgIcons } from '@/libs/svgicon';
import { getMerchantPcToolsUrls, pcToolsUrlCategoryApi } from '@/apis/chat';
import chatIcon from '../../static/icon/side-dh-icon.png';
import chatOnIcon from '../../static/icon/side-dh-on-icon.png';
import drawIcon from '../../static/icon/side-hh-icon.png';
import drawOnIcon from '../../static/icon/side-hh-on-icon.png';
import toolIcon from '../../static/icon/side-hk-icon.png';
import toolOnIcon from '../../static/icon/side-hk-on-icon.png';
import cooperationIcon from '../../static/icon/side-xz-icon.png';
import cooperationOnIcon from '../../static/icon/side-xz-on-icon.png';
// import { merchantGuid } from '@/config';
export default {
	name: 'layout-sider',

	async mounted() {
		// this.urls = await getMerchantPcToolsUrls();
		this.pcToolsUrlCategory();
	},
	data() {
		let pageName = this.$route.name;
		return {
			urls: [],
			otherUrls: [],
			nowCategory: '',
			isPop: false,
			pageName,
			iconPath: {
				chat: chatIcon,
				chatOn: chatOnIcon,
				draw: drawIcon,
				drawOn: drawOnIcon,
				tool: toolIcon,
				toolOn: toolOnIcon,
				cooperation: cooperationIcon,
				cooperationOn: cooperationOnIcon,
			},
		};
	},

	methods: {
		goLink(key) {
			this.isPop = false;
			this.nowCategory = '';
			this.$router.push('/app/' + key);
		},
		async pcToolsUrlCategory() {
			let res = await pcToolsUrlCategoryApi({ merchantGuid: this.$store.state.merchantGuid });
			this.urls = res.data;
		},
		async getMerchantPcTools(guid) {
			if (this.nowCategory !== guid) {
				this.nowCategory = guid;
				this.isPop = true;
				let res = await getMerchantPcToolsUrls({
					merchantGuid: this.$store.state.merchantGuid,
					categoryGuid: guid,
				});
				this.otherUrls = res;
			} else {
				this.isPop = false;
				this.nowCategory = '';
			}
		},
		onOpen(url) {
			this.isPop = false;
			this.nowCategory = '';
			window.open(url, '_blank');
		},
	},

	watch: {
		'$route.name': function (newName, oldName) {
			// 这里写你的逻辑
			this.pageName = newName;
		},
	},
};
</script>

<style lang="scss">
.layout-sider {
	border-right: solid 1px var(--border-color);
	background: #f6f7f9;
	padding-top: 10px;
	padding-right: 4px;
	height: 100%;
	width: 70px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	float: left;
	z-index: 10;
	position: relative;

	.menus {
		.item {
			&:hover {
				// color: var(--primary);
				// background: var(--primary-light);
				// border: 2px solid var(--primary);
				.icon {
					background: #e9e9f7;
					border-radius: 8px;
					// background: linear-gradient(95deg, #9854ec 0%, #4e58ec 53%, #145bec 100%);
					// background-clip: text;
					// color: transparent;
				}
			}

			&.active {
				color: var(--primary);
				.icon {
					background: #e9e9f7;
					border-radius: 8px;
					// background: linear-gradient(95deg, #9854ec 0%, #4e58ec 53%, #145bec 100%);
					// background-clip: text;
					// color: transparent;
				}
				// background: var(--primary-light);
				// border: 2px solid var(--primary);
			}

			color: var(--black);
			//border: 2px solid var(--border-color);
			// height: 54px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			cursor: pointer;
			// background: var(--btn-bg-color);
			border-radius: 6px;
			margin-bottom: 10px;
			font-size: 12px;
			.icon {
				width: 50px;
				height: 36px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				margin-bottom: 4px;
				img {
					display: block;
					width: 30px;
					height: 30px;
					border-radius: 50%;
				}
			}
		}
	}
}
.other-url-pop {
	position: fixed;
	width: 250px;
	height: 100vh;
	top: 0;
	left: 76px;
	padding: 20px;
	background-color: #ffffff;
	box-shadow: 5px 0 10px -5px #ccc;
	z-index: 9;
	padding-top: 53px;
	box-sizing: border-box;
	overflow-y: scroll;
	.item {
		display: flex;
		// justify-content: center;
		align-items: center;
		background: #f6f7f9;
		border-radius: 8px;
		margin-top: 12px;
		padding: 10px 16px;
		cursor: pointer;
		.img {
			display: block;
			width: 30px;
			height: 30px;
			border-radius: 50%;
		}
		.text {
			font-size: 14px;
			color: #333;
			margin-left: 10px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
}
</style>
