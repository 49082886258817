import { render, staticRenderFns } from "./layout-cooperation-main.vue?vue&type=template&id=0c756bba&scoped=true"
import script from "./layout-cooperation-main.vue?vue&type=script&lang=js"
export * from "./layout-cooperation-main.vue?vue&type=script&lang=js"
import style0 from "./layout-cooperation-main.vue?vue&type=style&index=0&id=0c756bba&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c756bba",
  null
  
)

export default component.exports