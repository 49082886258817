import axios from 'axios'
import { Message } from 'element-ui'
// import router from '@/router/index'
import store from '@/store'
import { getToken, removeSession, removeToken } from '@/utils/auth'
import { parseInt } from 'lodash'
import { randomStr, queryStr } from '@/libs/request-random'
import { autographFun } from '@/libs/request-generateSignature'
// import { merchantGuid } from '@/config';
const service = axios.create({
  baseURL: 'https://ai-api.deepcity.cn/',
  // baseURL: 'https://xytestapi.aiaskbot.cn/',
  timeout: 20000, // request timeout
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [function (data) {
    return JSON.stringify(data) || {}
  }]
})


const service_xg = axios.create({
  baseURL: 'https://ai-api.deepcity.cn/',
  // baseURL: 'https://xytestapi.aiaskbot.cn/',
  timeout: 20000, // request timeout
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [function (data) {
    return JSON.stringify(data) || {}
  }]
})
const requestFun = (config) => {
  config.urlSuffix = {
    app_type: 'xiaoyipc', //应用类型：xiaoyipc
    app_guid: 'xiaoyipc', //应用唯一标识
    expires: parseInt((new Date().getTime() / 1000).toFixed(0)), //当前时间戳
    token: getToken() || '',
    noncestr: randomStr(true, true, true, 32),
    merchantGuid: store.state.merchantGuid,
  }
  config.urlSuffix.signature = encodeURIComponent(autographFun(config)) //签名
  config.url = config.url + queryStr(config.urlSuffix, config.url)

  return config
}
service.interceptors.request.use(requestFun,
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
service_xg.interceptors.request.use(requestFun,
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
service_xg.interceptors.response.use(
  response => {
    if (response.data.code == 704001) {
      removeToken();
      Message.closeAll()
      Message({
        message: '登录失效,请重新登录。',
        type: 'error',
        duration: 2000
      })
      this.$router.push('/');
      return
    }
    const res = response
    return res.data
  },
  error => {
    Message({
      message: '访问异常，请稍后重试',
      type: 'error',
      duration: 5 * 1000
    })
    return null
  }
)
service.interceptors.response.use(
  response => {
    if (response.data.code == 704001) {
      removeToken();
      Message.closeAll()
      Message({
        message: '登录失效,请重新登录。',
        type: 'error',
        duration: 2000
      })
      this.$router.push('/');
      return
    }
    const res = response
    return res.data
  },
  error => {
    Message({
      message: '访问异常，请稍后重试',
      type: 'error',
      duration: 5 * 1000
    })
    return null
  }
)

export { service, service_xg }
