<template>
  <div>
    <el-header class="layout-header center" height="52px">
      <div class="logo">{{ pcConfig.title }}</div>
      <div class="flex user-box">
        <div class="btn-buy-tc" @click="showPayMeal">
          <img class="icon" src="../../static/icon/header-cart-icon.png" /> 购买套餐
        </div>
        <div class="btn-buy-vip" @click="showVipDialog">
          <img class="icon" src="../../static/icon/header-vip-icon.png" /> 购买会员
        </div>
        <el-dropdown trigger="click" class="user-dropdown" v-show="session">
          <span class="flex-center">
            <!-- v-show="session.headImgUrl" -->
            <img class="header-img" title="点击修改头像" :src="session.headImgUrl">
            <!-- <div slot="placeholder">.</div> -->
            </img>
            <!-- <div class="ml10 user-name">{{ session.nickname }}</div> -->
          </span>

          <el-dropdown-menu slot="dropdown">
            <div class="user-center-menu">
              <div class="logo-box">
                <div class="logo">
                  <img class="header-img-large" :src="session.headImgUrl"></img>
                </div>
                <div class="name-box">
                  <div class="vip" v-if="isVip">
                    <img class="img" src="../../static/icon/big-vip-header.png" />
                    {{ bigVipInfo.cardInfo.cardName }}
                  </div>
                  <div class="name">{{ session.nickname }}</div>
                </div>
                <div class="edit-box" @click="showModifyUser"><i class="el-icon-edit"></i></div>
              </div>
              <div class="card-box">
               <div class="point">
                <span>算力余额：{{ session.chat_count }}</span>
                <span class="meal" @click="showPayMeal">去充值</span>
               </div>
                <div class="qy-box" v-if="isBigVip">
                  <div>
                    <div class="label">会员权益生效中</div>
                    <div class="date">权益有效期：{{ bigVipInfo.vipInfo.cardEndTime }}</div>
                  </div>
                  <div class="btn" @click="onGotoBigvip">
                    <img class="img" src="@/static/icon/header-bigvip-qy.png" />权益中心
                  </div>
                </div>
              </div>
              <!-- <div class="item-box point">算力余额: {{ session.chat_count }} <a href="#"  @click="showPayMeal">充值</a> </div> -->
              <div class="item-box">联系客服</div>
              <div class="item-box flex space-between">
                <span>分享得算力奖励</span>
                <span @click="copyPath">复制链接</span>
              </div>
              <div class="item-box" @click="logout">退出登录</div>
              <!-- <el-dropdown-item class="flex-between">
							<div class="font-bolder">我的昵称</div>
							<div class="flex-center">
								<div>{{ session.nickname }}</div>
							</div>
						</el-dropdown-item>
						<el-dropdown-item class="flex-between">
							<div class="font-bolder">
								助理算力
							</div>
							<div class="flex-between">
								<div>{{ session.chat_count }} 算力</div>
							</div>
						</el-dropdown-item>
						<el-dropdown-item>
							<div class="font-bolder">联系客服</div>
						</el-dropdown-item>
						<el-dropdown-item class="flex-between" v-if="isVip">
							<div class="font-bolder">会员权益生效中</div>
							<div class="flex-between">
								<el-button @click="onGotoBigvip" class="pay" type="primary" round>权益中心</el-button>
							</div>
						</el-dropdown-item>
						<el-dropdown-item class="logout" @click.native="logout">
							<el-button type="danger" round>退出登录</el-button>
						</el-dropdown-item> -->
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!-- 修改个人 -->
      <el-dialog id="modify-user" title="修改个人信息" :append-to-body="true" :visible.sync="modifyDialogVisible" width="30%">
        <div class="flex-center">
          <el-upload class="avatar-uploader" action="https://ai-api.deepcity.cn/user/api.userinfo/uploadImg"
            :show-file-list="false" name="img" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <div class="relative">
              <el-image class="header-img" title="点击上传头像" :src="modifyHeaderImg"></el-image>
              <div class="uploading flex-center" v-show="uploading">
                <i class="spin el-icon-loading"></i>
              </div>
              <a class="btn-edit" title="点击上传头像">
                <i class="el-icon-upload2"></i>
              </a>
            </div>
          </el-upload>
        </div>

        <div class="flex-center mt20">
          <el-input style="width: 60%" v-model="modifyNickname" placeholder="请输入昵称"></el-input>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="modifyDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitModifyUserInfo">提交</el-button>
        </span>
      </el-dialog>

      <el-dialog id="pay-user" title="支付" :append-to-body="true" :visible.sync="payDialogVisible" width="30%">
        <div class="flex-center mb40">
          <el-image :src="payImage" style="width: 200px"></el-image>
        </div>

        <el-alert center title="请打开小程序扫码充值" type="success" :closable="false"></el-alert>

        <!--            <span slot="footer" class="dialog-footer">-->
        <!--                <el-button @click="payDialogVisible = false">取 消</el-button>-->
        <!--                <el-button type="primary" @click="submitModifyUserInfo">提交</el-button>-->
        <!--            </span>-->
      </el-dialog>
    </el-header>

    <pay-meal-dialog ref="paymeal" @onBuyMeal="onBuyMeal"></pay-meal-dialog>
    <pay-vip-dialog ref="vipDialogRef" @onBuyVip="onBuyVip"></pay-vip-dialog>
  </div>
</template>

<script>
import { getSession, getToken, removeToken } from '@/utils/auth';
import { headerImg } from '@/config';
import { getUserBigVipInfoApi,getUserInfo, updateUserInfo, lunciHelpInfo, joinHelpLunci, getPuserVipInfoApi,getPcShowConfigApi } from '@/apis/user';
import PayMealDialog from '@/views/app/dialogs/pay-meal-dialog.vue';
import PayVipDialog from '@/views/app/dialogs/pay-vip-dialog.vue';

export default {
  name: 'layout-header',
  components: { PayMealDialog, PayVipDialog },

  async mounted() {
    let yqGuid = localStorage.getItem('yqGuid');
    if (yqGuid) {
      this.showYQConfirm(yqGuid);
    }
    this.getUserVipInfo();
    this.getUserBigVipInfo();
    this.getPcShowConfig()
    this.getUserInfoFun()
  },

  data() {
    let token = getToken();
    return {
      modifyNickname: '',
      modifyHeaderImg: '',
      uploading: false,
      session: {},
      token,
      headerImg,
      modifyDialogVisible: false,
      payDialogVisible: false,
      payImage: '',
      isVip: false,
      isBigVip:false,
      bigVipInfo: {
        cardInfo: {
          cardName: '',
        },
        vipInfo: {
          cardEndTime: '',
        },
      },
      invitationPath:'',
      pcConfig: {
				title: '',
			},
    };
  },

  methods: {
    async getUserInfoFun(){
      let res = await getUserInfo();
      this.session = getSession();
      if(res.code==0){
        let origin = window.location.origin;
				this.invitationPath = `${origin}/?inviteCode=${res.data.guid}&type=user`;
      }
    },
    async getPcShowConfig() {
			let { data } = await getPcShowConfigApi({ merchantGuid:this.$store.state.merchantGuid });
			this.pcConfig = data;
      document.title = data.title ? data.title : 'AI龙龙，一个人的工作站';
		},
    copyPath(){
      this.$copyText(this.invitationPath);
			this.$message({ message: '已复制分享链接', type: 'success' });
    },
    async getUserBigVipInfo() {
      let res = await getUserBigVipInfoApi();
      this.isBigVip = res.data.isBigVip;
    },
    async getUserVipInfo() {
      let res = await getPuserVipInfoApi();
      this.bigVipInfo = res.data;
      this.isVip = res.data.isVip;
    },
    onBuyVip() {
      this.getUserVipInfo();
    },
    async onBuyMeal() {
      await getUserInfo();
      this.session = getSession();
    },
    onGotoBigvip() {
      this.$router.push('/app/bigvip');
    },
    showPayMeal() {
      this.$refs.paymeal.show();
    },
    showVipDialog() {
      this.$refs.vipDialogRef.show();
    },
    async showYQConfirm(guid) {
      let res = await lunciHelpInfo({ chatLunciGuid: guid });
      if (res.code !== 0) {
        this.$message({
          type: 'info',
          message: res.msg || '获取协作聊天失败',
        });
        localStorage.setItem('yqGuid', '');
        return;
      }
      let title = `用户${res.data.lunciUser}邀请您为他协作${res.data.lunciName},是否接受。`;
      this.$confirm(title, '协作邀请', {
        confirmButtonText: '接收',
        cancelButtonText: '拒绝',
      })
        .then(async () => {
          await joinHelpLunci({ chatLunciGuid: guid });
          localStorage.setItem('yqGuid', '');
          this.$router.push('/app/cooperation');
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '拒绝邀请',
          });
          localStorage.setItem('yqGuid', '');
        });
    },
    // async showPay() {
    // 	this.payDialogVisible = true;
    // },

    async submitModifyUserInfo() {
      this.modifyDialogVisible = false;
      let r = await updateUserInfo({
        headImgUrl: this.modifyHeaderImg,
        nickname: this.modifyNickname,
      });
      if (r && this.$message({ message: '修改成功', type: 'success' })) {
        await getUserInfo();
        this.session = getSession();
      }
    },

    logout() {
      removeToken();
      this.$store.commit('clearMerchantGuid')
      this.$router.push('/');
    },

    showModifyUser() {
      this.modifyDialogVisible = true;
      this.modifyNickname = this.session.nickname;
      this.modifyHeaderImg = this.session.headImgUrl;
    },

    handleAvatarSuccess({ code, data }) {
      if (code == 0) {
        this.modifyHeaderImg = data;
      }
      this.uploading = false;
    },

    beforeAvatarUpload() {
      this.uploading = true;
    },
  },
};
</script>

<style lang="scss">
.layout-header {
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  height: 30px;
  background-color: #fafafa;
  width: 100%;
  --wails-draggable: drag;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  top: 0;

  .btn-buy-tc {
    border-radius: 20px;
    width: 108px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #9854ec 0%, #4e58ec 53%, #145bec 100%);
    font-size: 14px;
    color: #fff;
    margin-right: 10px;
    cursor: pointer;

    .icon {
      width: 30px;
      height: 30px;
      display: block;
    }
  }

  .btn-buy-vip {
    border-radius: 20px;
    width: 108px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #684821 0%, #453015 53%, #251a0c 100%);
    font-size: 14px;
    color: #f5d192;
    margin-right: 10px;
    cursor: pointer;

    .icon {
      width: 30px;
      height: 30px;
      display: block;
    }
  }

  .logo {
    font-size: 22px;
    background: linear-gradient(110deg, #d072ff 0%, #06f2ff 47%, #1d75ff 100%);
    font-weight: bold;
    background-clip: text;
    color: transparent;
  }

  .header-img {
    width: 36px;
    height: 36px;
  }

  .user-name {
    font-size: 12px;
  }

  .user-dropdown {
    position: relative;
    background: var(--primary-light);
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #2b1e0e;
    overflow: hidden;
  }

  .el-image__placeholder {
    display: none !important;
  }
}

.user-center-menu {
  width: 400px;
  padding: 10px 20px;
  box-sizing: border-box;

  .logo-box {
    display: flex;
    align-items: center;

    .header-img-large {
      width: 59px;
      height: 59px;
      display: block;
      border-radius: 50%;
    }

    .name-box {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-left: 20px;

      .vip {
        color: #6126f5;
        font-size: 16px;
        display: flex;
        align-items: center;

        .img {
          width: 22px;
          height: 22px;
          display: block;
          margin-right: 6px;
        }
      }

      .name {
        font-size: 18px;
        color: #333333;
      }
    }

    .edit-box {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .card-box {
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 16px;
    background: linear-gradient(137deg, #edf2fe 0%, #f4efff 100%);
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;

    .point {
      font-size: 14px;
      color: #6126f5;
      // text-align: right;
      display: flex;
      justify-content: space-between;
      .meal{
       cursor: pointer;
      }
    }

    .qy-box {
      display: flex;
      justify-content: space-between;
      background-color: #ffffff;
      padding: 15px;
      border-radius: 6px;
      margin-top: 10px;

      .label {
        color: #333333;
        font-size: 16px;
      }

      .date {
        color: #7f8792;
        font-size: 14px;
        margin-top: 4px;
      }

      .btn {
        width: 100px;
        height: 40px;
        background: linear-gradient(131deg, #9854ec 0%, #4e58ec 53%, #145bec 100%);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #fff;
        cursor: pointer;

        .img {
          width: 25px;
          height: 25px;
          display: block;
        }
      }
    }
  }

  .item-box {
    cursor: pointer;
    font-size: 16px;
    color: #333333;
    padding: 10px;

    &:hover {
      color: #6126f5;
    }
  }

  // width: 300px;
  // position: relative;

  // button.pay {
  // 	border-radius: 30px;
  // 	font-size: 10px;
  // 	padding: 4px 8px 4px 6px;
  // }

  // .header-img-large {
  // 	border-radius: 100%;
  // 	width: 90px;
  // 	height: 90px;
  // 	border: solid 3px var(--primary);
  // }

  // button {
  // 	font-weight: normal;
  // 	margin-left: 10px;
  // }

  // .logout {
  // 	margin-top: 10px;

  // 	&:hover {
  // 		background: white !important;
  // 	}

  // 	button {
  // 		margin: 0;
  // 		width: 100%;
  // 		padding: 4px 15px;
  // 	}
  // }

  // .btn-edit {
  // 	font-size: 12px;
  // 	color: var(--primary);
  // }
}

#modify-user {
  .header-img {
    border-radius: 100%;
    width: 80px;
    height: 80px;
  }

  .uploading {
    border-radius: 100%;
    width: 80px;
    height: 80px;
    font-size: 20px;
    color: var(--primary);
    background: #ede4fe66;
    position: absolute;
    top: 0;
    //border: 1px solid var(--primary);
  }
}
</style>
