<template>
  <div class="layout-tools-left">
    <div class="tab-box">
      <div :class="['tab-item', { active: sceneListId === item.id }]" v-for="(item, index) in classList" :key="index"
        @click="onChengOptions(item)">
        {{ item.title }}
      </div>
    </div>
    <div class="content-list">
      <div :class="['item-box', { 'active': activeClassId === item.id }]" v-for="item in contentList">
        <img class="image" :src="item.image" @click="onItem(item)"></img>
        <div class="title-box">
          <div class="collect" @click="onCollect(item)">
            <i :class="['el-icon-star-off', { 'el-icon-star-on': item.isCollect }]"></i>
          </div>
          <div class="title">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClassListApi, collectClassListApi, getClassContentListApi, cancelClassApi, collectClassApi } from '@/apis/tools';
// import { merchantGuid } from '@/config';
export default {
  name: 'layout-chat-left',
  props: {},
  data() {
    return {
      sceneListId: '',
      activeClassId: '',
      classList: [
        {
          id: 0,
          title: '我的收藏',
        },
      ],
      contentList: [],
    };
  },
  mounted() {
    this.getClassList();
  },
  methods: {
    async getClassList() {
      let res = await getClassListApi({ cateType: 'text', merchantGuid:this.$store.state.merchantGuid });
      this.classList.push(...res.data);
      this.sceneListId = res.data[0].id;
      this.getClassContentList();
    },
    onChengOptions(item) {
      this.sceneListId = item.id;
      if (item.id === 0) {
        this.collectClassList();
      } else {
        this.getClassContentList();
      }
    },
    async collectClassList() {
      let res = await collectClassListApi({ cateType: 'text' });
      let list = [];
      res.data.forEach(item => {
        if (item.categoryInfo) {
          item.categoryInfo.isCollect = true;
          list.push(item.categoryInfo);
        }
      });
      this.contentList = list;
    },
    async getClassContentList() {
      let req = {
        cateId: this.sceneListId,
      };
      let res = await getClassContentListApi(req);
      this.contentList = res.data;
      if (!this.activeClassId && res.data.length > 0) {
        this.activeClassId = res.data[0].id;
        this.$emit('changeActiveClassId', this.activeClassId)
      }
    },
    onItem(item) {
      this.activeClassId = item.id;
      this.$emit('changeActiveClassId', item.id)
    },
    async onCollect(item) {
      if (this.activeClassId === 0) {
        await cancelClassApi({ cateId: item.id });
        this.$message({
          message: '取消收藏',
          type: 'success'
        });
      } else {
        if (item.isCollect) {
          await cancelClassApi({ cateId: item.id });
          this.$message({
            message: '取消收藏',
            type: 'success'
          });
        } else {
          console.log(item, 'itemitemitemitem')
          await collectClassApi({ cateId: item.id });
          this.$message({
            message: '收藏成功',
            type: 'success'
          });
        }
      }
      if (this.activeClassId === 0) {
        this.collectClassList();
      } else {
        this.getClassContentList();
      }
    },
  },
};
</script>

<style lang="scss">
.layout-tools-left {
  width: 300px;
  border-right: solid 1px var(--border-color);
  padding: 10px;
  // background: rgb(250 251 255);
  background-color: #fff;
  overflow-y: auto;
  box-sizing: border-box;

  .tab-box {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #EBEBEB;

    .tab-item {
      width: 100%;
      font-size: 16px;
      padding: 10px 10px;
      cursor: pointer;
      border-radius: 8px;
      color: #222222;
      font-size: 14px;
      box-sizing: border-box;
      margin-bottom: 6px;

      &:hover,
      &.active {
        background: #F6F7F9;
      }

      // &.active::after {
      // 	content: '';
      // 	position: absolute;
      // 	bottom: -2px;
      // 	left: 50%;
      // 	transform: translateX(-50%);
      // 	width: 30px;
      // 	height: 3px;
      // 	border-radius: 2px;
      // 	background-color: var(--primary);
      // }
    }
  }

  .content-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item-box {
      width: 48%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      // padding: 4px;
      border-radius: 4px;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;

      &.active {
        border: 1px solid var(--primary);

        .title-box {
          background: linear-gradient(90deg, #9854EC 0%, #4E58EC 53%, #145BEC 100%);
        }


      }

      .image {
        width: 100%;
        height: 166px;
        border-radius: 4px;
        cursor: pointer;
      }

      .title-box {
        display: flex;
        justify-content: space-between;
        padding: 6px;
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.6);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);

        .title {
          font-size: 14px;
          flex: 1;
          text-align: center;
        }

        .collect {
          cursor: pointer;

          i {
            font-size: 18px;
            color: #fff;

            &.el-icon-star-on {
              color: #fff;
              // background-color: var(--primary);
              border-radius: 50%;
              padding: 2px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .layout-tools-left {
    width: 200px;

    .tab-box {
      margin-bottom: 20px;

      .tab-item {
        font-size: 14px;
      }
    }

    .content-list {
      .item-box {
        .image {
          height: 100px;
        }

        .title-box {
          .title {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
